import React from 'react'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'
import PortalRedirectStyle, { PortalRedirectModalStyle } from './style'
import PortalRedirectButton from '../../components/PortalRedirectButton/PortalRedirectButton.component'
import confJson from '../../ABapi/configuration.json'

const PortalRedirectPage = () => {
    return (
        <PortalRedirectStyle>
            <PortalRedirectModalStyle>
                <header>
                    <Logo className="ab-logo" />
                </header>
                <main>
                    <h1 className="step-title">
                        Accesso all'area clienti
                    </h1>
                   <p className="form-description">
                        Per accedere alla tua area clienti collegati al sito www.segnoverde.it, come indicato sulla tua ultima fattura.
                    </p>
                </main>
            </PortalRedirectModalStyle>
        </PortalRedirectStyle>
    )
}

export default PortalRedirectPage
